import React from "react"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Title from "../components/title"

const About = () => (
  <StaticQuery query={graphql`
    query {
      desktop: file(relativePath: { eq: "sarah-dorweiler-x2Tmfd1-SgA-unsplash.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `}

  render={data => {
    // Set ImageData.
    const imageData = data.desktop.childImageSharp.fluid
    return (
      <BackgroundImage id="about" Tag="div" className="full-page" fluid={imageData}>
        
        
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-6">
              <div className="black-panel">
                <h1>About Us</h1>

                <p>We're a small Bendigo-based team of Software and Electronic Engineers. With decades of experience in
                providing custom software solutions and products, we have developed successful applications across the fields of biosecurity, 
                emergency management, agribusiness, finance, aviation, and manufacturing.</p>
                <p>Our team recognise that customer support is just as important as great software, and we pride ourselves on building strong
                relationships with clients.</p>

              </div>
              
            </div>
          </div>
        </div>

      </BackgroundImage>
    )
  }}
/>
)

export default About
