import React from "react"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const Agents = () => (
  <StaticQuery query={graphql`
    query {
      desktop: file(relativePath: { eq: "gertruda-valaseviciute-xMObPS6V_gY-unsplash.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `}

  render={data => {
    // Set ImageData.
    const imageData = data.desktop.childImageSharp.fluid
    return (
      <BackgroundImage id="agents" Tag="div" className="full-page" fluid={imageData}>
        
        
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="white-panel">
                <h1>AI Agents</h1>

                <p>Use our flexible Agents which employ a combination of Artificial Intelligence, Machine Learning and Image processing to perform tasks including:</p>
                <ul>
                  <li>Level reading water channels using webcam images</li>
                  <li>Determining if a drainage gate is blocked</li>
                  <li>Detecting the presence of vehicles, people and other objects</li>
                </ul>

              </div>
              
            </div>
          </div>
        </div>

      </BackgroundImage>
    )
  }}
/>
)

export default Agents