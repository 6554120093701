import PropTypes from "prop-types"
import React from "react"
import Loadable from 'react-loadable';
import Screenshot from "../components/Screenshot";
import Title from "../components/title";
import BridgeTitle from "../components/bridgeTitle";
import FlowTitle from "../components/flowTitle"

function Loading(props) {
  if (props.error) {
    return <div>Error! <button onClick={ props.retry }>Retry</button></div>;
  } else if (props.pastDelay) {
    return <div style={{
      position: 'absolute',
      backgroundSize: `cover`,
      backgroundRepeat: `no-repeat`,
      backgroundPosition: `50% 50%`,
      top: 0, left: 0,
      width: `100%`, height: `100%`,
      background: `#4087ea`,
      background: `-moz-linear-gradient(top,  ${topColor} 0%, ${bottomColor} 100%)`,
      background: `-webkit-gradient(linear, left top, left bottom, color-stop(0%,${topColor}), color-stop(100%,${bottomColor}))`,
      background: `-webkit-linear-gradient(top,  ${topColor} 0%,${bottomColor} 100%)`,
      background: `-o-linear-gradient(top, ${topColor} 0%,${bottomColor} 100%)`,
      background: `-ms-linear-gradient(top,  ${topColor} 0%,${bottomColor} 100%)`,
      background: `linear-gradient(to bottom,  ${topColor} 0%,${bottomColor} 100%)`      
      }} ></div>;
  } else {
    return null;
  }
}

const ParticleLoader = Loadable.Map({
  loader: { particles: () => import('particles.js')},
  loading: Loading,
  render(loaded, props) {

    return (
    <div id="particles-js" style={{
      position: 'absolute',
      backgroundSize: `cover`,
      backgroundRepeat: `no-repeat`,
      backgroundPosition: `50% 50%`,
      top: 0, left: 0,
      width: `100%`, height: `100%`,
      background: `#4087ea`,
      background: `-moz-linear-gradient(top,  ${topColor} 0%, ${bottomColor} 100%)`,
      background: `-webkit-gradient(linear, left top, left bottom, color-stop(0%,${topColor}), color-stop(100%,${bottomColor}))`,
      background: `-webkit-linear-gradient(top,  ${topColor} 0%,${bottomColor} 100%)`,
      background: `-o-linear-gradient(top, ${topColor} 0%,${bottomColor} 100%)`,
      background: `-ms-linear-gradient(top,  ${topColor} 0%,${bottomColor} 100%)`,
      background: `linear-gradient(to bottom,  ${topColor} 0%,${bottomColor} 100%)`      
      }} 
      ref={(r) => {
        if (window) {
          setTimeout(() => {
            window.particlesJS.load('particles-js', '/particlesjs-config.json', function() {
            });
          }, 500);
        }
      }}
      >
    </div>
    );
  }
});


const topColor = `#444`;
const bottomColor = `#888`;
const Splash = () => (
  <div className="full-page" style={{ position: 'relative' }}>
    <ParticleLoader />




    <div className="container">
        <div className="row">
            <div className="col-12">
              <div className="d-none d-lg-block" style={{ minHeight: '15vw' }}></div>
            </div>

            <div className="col-xl-6 mb-3">

                <div className="black-panel">
                    <h1><Title /> IoT platform</h1>
                    <p>Complete remote data capture and device management</p>
                </div>

                <div className="white-panel">
                    <strong><Title /> CLOUD</strong>
                    <p>Start collecting data immediately with everything managed for you.</p>
                </div>

                <div className="white-panel">
                    <strong><Title /> ON-PREM</strong>
                    <p>Run a local version of our cloud platform, with optional integration with your existing SCADA system.</p>
                </div>

                <div className="white-panel">
                    <strong><Title /> HYBRID</strong>
                    <p>Run a local <BridgeTitle /> to connect your on-prem devices and/or existing SCADA system to <Title /> CLOUD.</p>
                </div>

                <div className="white-panel">
                    <strong><BridgeTitle /></strong>
                    <p>Connect your on-prem devices and/or existing SCADA system to <Title /> via a number of protocols such as DNP3, MQTT, or HTTP. An additional TCP-2-MQTT bridge can allow connection to devices that use other proprietary protocols.</p>
                </div>

                <div className="white-panel">
                  <strong><FlowTitle /></strong>
                  <p>Coming soon...</p>
                </div>
            </div>
            <div className="col-xl-6">
                <Screenshot></Screenshot>
            </div>
        </div>

    </div>

  </div>
)

Splash.propTypes = {
  siteTitle: PropTypes.string,
}

Splash.defaultProps = {
  siteTitle: ``,
}

export default Splash
