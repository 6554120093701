import React from "react"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Title from "../components/title"

const About = () => (
  <StaticQuery query={graphql`
    query {
      desktop: file(relativePath: { eq: "jeremy-ricketts-6ZnhM-xBpos-unsplash.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `}

  render={data => {
    // Set ImageData.
    const imageData = data.desktop.childImageSharp.fluid
    return (
      <BackgroundImage id="contactus" Tag="div" className="full-page" fluid={imageData}>
        
        
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-6">
              <div className="black-panel">
                <h1>Contact Us</h1>
                <p>Feel free to contact us with any questions about how <Title /> can benefit your business.</p>
                {/* coldtap.io */}{/* <p>Email: <a href="mailto:&#104;&#101;&#108;&#108;&#111;&#064;&#099;&#111;&#108;&#100;&#116;&#097;&#112;&#046;&#105;&#111;?subject=Contact us">&#104;&#101;&#108;&#108;&#111;&#064;&#099;&#111;&#108;&#100;&#116;&#097;&#112;&#046;&#105;&#111;</a></p> */}
                {/* process.io*/}<p>Email: <a href="mailto:&#104;&#101;&#108;&#108;&#111;&#064;&#112;&#114;&#111;&#099;&#101;&#115;&#115;&#114;&#046;&#105;&#111;?subject=Contact us">&#104;&#101;&#108;&#108;&#111;&#064;&#112;&#114;&#111;&#099;&#101;&#115;&#115;&#114;&#046;&#105;&#111;</a></p>
              </div>
            </div>
          </div>
        </div>

      </BackgroundImage>
    )
  }}
/>
)

export default About
