import React from "react"
import SouthLogo from "../components/37sLogo"
import ConnectM2MLogo from "../components/ConnectM2MLogo"

const Partners = () => {

    return (

        <div id="partners" style={{ paddingTop: '6rem', paddingBottom: '4rem' }}>
        <div className="container">
          <h1>Partners</h1>
  
          <div className="row">
            <div className="col-md-4 mb-3">
              <div className="card text-center h-100 align-middle  justify-content-center p-3">
                <a href="https://37s.com.au/" target="_blank" rel="noopener noreferrer"><SouthLogo /></a>
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="card text-center h-100 align-middle p-3">
              <a href="https://connectm2m.co.nz" target="_blank" rel="noopener noreferrer"><ConnectM2MLogo /></a>
              </div>
            </div>
          </div>
      
        </div>
  
      </div>

    )

}

export default Partners