import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Splash from "./splash"
import Water from "./water"
import Agents from "./agents"
import Partners from "./partners"
import About from "./about"
import ContactUs from './contactus';


const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    {/* <h1>Welcome</h1> */}

    <Splash />
    <Water />
    <Agents />
    <Partners />
    <About />
    <ContactUs />

    {/* <p>Welcome to Processr.io your end-to-end IoT and data processing platform.</p>
    <ul>
      <li>Use our scalable and secure cloud environment or opt for a private on-premise installation</li>
      <li>Connect your IoT devices or industrial/environmental sensors and data loggers via DNP3, MQTT, Modbus TCP, HTTP/S, WebSockets, FTP, Email or via custom APIs</li>
      <li>Securely communicate to Processr.io via your existing VPN or private 3G/4G network</li>
      <li>Remotely manage devices over DNP3; make changes manually or respond automatically to external events such as weather or the change of seasons</li>
      <li>Configure alerts and actions using our flexible processing engine</li>
      <li>Display live and historical data on dashboards</li>
      <li>Upload captured images and use our Image AI engine for classification, statuses and alerts</li>
      <li>Use Processr Bridge to map DNP3 outstations to otherwise incompatible SCADA systems or networks</li>
    </ul> */}






    {/*  */}
  </Layout>
)

export default IndexPage
